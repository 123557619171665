import { configureStore } from '@reduxjs/toolkit'
import databranchReducer from './Feature/Settings/Branch'
import dataRegimenReducer from './Feature/Settings/Regimen'
import dataPayMethodReducer from './Feature/Settings/PayMethod'
import dataCFDIReducer from './Feature/Settings/CFDI'
import dataSystemServiceReducer from './Feature/Settings/SystemService'
import dataSystemTypeReducer from './Feature/Settings/SystemType'
import dataEndpointReducer from './Feature/Settings/Endpoint'
import dataCategoryReducer from './Feature/Settings/Category'
import dataGastosReducer from './Feature/Settings/Gastos'
import dataProductReducer from './Feature/Settings/Product'
import dataSuplierReducer from './Feature/Settings/Suplier'
import dataWarehouseReducer from './Feature/Settings/Warehouse'
import dataVehicleEventReducer from './Feature/Settings/VehicleEvent'
import dataVehicleTypeReducer from './Feature/Settings/VehicleType'
import dataTaskEventReducer from './Feature/Settings/TaskEvent'
import miscReducer from './Misc'
import usermainReducer from './Feature/User/UserMain'
import projectControlReducer from './Feature/Project/Controller'
import serviceControlReducer from './Feature/Service/Controller'
import bitacoraControlReducer from './Feature/Bitacora/Controller'
import attendanceControlReducer from './Feature/Attendance/Controller'
import deviceControlReducer from './Feature/Device/Controller'
import vehicleControlReducer from './Feature/Vehicle/Controller'

import clientCollectionReducer from './Feature/Client/Collection'
import userCollectionReducer from './Feature/User/Collection'
import projectCollectionReducer from './Feature/Project/Collection'
import serviceCollectionReducer from './Feature/Service/Collection'
import bitacoraCollectionReducer from './Feature/Bitacora/Collection'
import attendanceCollectionReducer from './Feature/Attendance/Collection'
import deviceCollectionReducer from './Feature/Device/Collection'
import vehicleCollectionReducer from './Feature/Vehicle/Collection'

import projectReducer from './Feature/Project/Project'
import serviceReducer from './Feature/Service/Service'
import mainReducer from './Feature/Main/Main'

export const store = configureStore({
    reducer: {
        databranch: databranchReducer,
        dataregimen: dataRegimenReducer,
        datapaymethod: dataPayMethodReducer,
        dataCFDI: dataCFDIReducer,
        dataSystemService: dataSystemServiceReducer,
        dataSystemType: dataSystemTypeReducer,
        dataEndpoint: dataEndpointReducer,
        dataCategory: dataCategoryReducer,
        dataGastos: dataGastosReducer,
        dataProduct: dataProductReducer,
        dataSuplier: dataSuplierReducer,
        dataWarehouse: dataWarehouseReducer,
        dataVehicleEvent: dataVehicleEventReducer,
        dataVehicleType: dataVehicleTypeReducer,
        dataTaskEvent: dataTaskEventReducer,
        miscelaneos: miscReducer,
        usermain: usermainReducer,
        projectControl: projectControlReducer,
        serviceControl: serviceControlReducer,
        bitacoraControl: bitacoraControlReducer,
        attendanceControl: attendanceControlReducer,
        deviceControl: deviceControlReducer,
        vehicleControl: vehicleControlReducer,
        clientCollection: clientCollectionReducer,
        userCollection: userCollectionReducer,
        projectCollection: projectCollectionReducer,
        serviceCollection: serviceCollectionReducer,
        bitacoraCollection: bitacoraCollectionReducer,
        attendanceCollection: attendanceCollectionReducer,
        deviceCollection: deviceCollectionReducer,
        vehicleCollection: vehicleCollectionReducer,
        // Single Object
        dataProject: projectReducer,
        dataService: serviceReducer,
        dataMain: mainReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})